import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/core";
import {  Redirect  } from "react-router-dom";
import { connect } from "react-redux";

import { wrapperStyle,  logoutBtn } from "./style";

import * as actions from "../../store/action";


class HomePage extends React.Component {
	render() {
		let authRedirect = null;
		if (this.props.isLoggedIn) {
			authRedirect = <Redirect to="/groups" />;
		}

		return (
			<div css={wrapperStyle()}>
				{authRedirect}
				<div css={logoutBtn()}>
					Please login from <a href="https://prpwebs.com" style={{color:"#000"}}>Here</a>
				</div>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		loading: state.loading,
		error: state.error,
		isLoggedIn: state.isLoggedIn,
	};
};

const mapDispatchToProps = dispatch => {
	return {
		onLogout: () => dispatch(actions.logout()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
