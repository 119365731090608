import React from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Global } from "@emotion/core";

import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { CometChatAvatar } from '../../cometchat-pro-react-ui-kit/CometChatWorkspace/src';
import { COMETCHAT_CONSTANTS } from '../../consts';

import {
  wrapperStyle,
  errorStyle,
  titleStyle,
  titleStyle1,
  subtitleStyle,
  userContainerStyle,
  userWrapperStyle,
  thumbnailWrapperStyle,
  uidWrapperStyle,
  inputWrapperStyle,
  loginBtn,
} from "./style";

import { loaderStyle } from "./loader";

import * as actions from '../../store/action';

class KitchenSinkApp extends React.PureComponent {

  constructor(props) {
    super(props);
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if(params.user){
        this.login(params.user)
    }
    this.myRef = React.createRef();
  }

  login = (uid) => {
    
    if(!uid) {
      uid = this.myRef.current.value;
    }

    this.uid = uid;
    this.props.onLogin(this.uid, COMETCHAT_CONSTANTS.AUTH_KEY);
  }
  
  render() {

    const params1 = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    let loader = null;
    if (this.props.loading) {
      loader = (<div className="loading">Loading...</div>);
    }

    let errorMessage = null;
    if (this.props.error) {
      errorMessage = (<p css={errorStyle()}>{this.props.error.message}</p>);
    }

    let authRedirect = null;
    if (this.props.isLoggedIn) {
      authRedirect = <Redirect to="/groups" />
    }

    if(!params1.user){
        return (
          <React.Fragment>
          <Global styles={loaderStyle} />
        
          <div css={wrapperStyle()}>
              {authRedirect}
              {loader}
              {errorMessage}
              <p css={titleStyle()}>PRP Webs Dashbaord</p>
              <p css={subtitleStyle()}>If you don't know your user id. Try login from <a href="https://prpwebs.com/" style={{color:'black'}}>Here</a></p>
              <br/>
              <div css={uidWrapperStyle()}>
                <div>
                  <p css={subtitleStyle()}>Login with UID</p>
                </div>
                <div css={inputWrapperStyle()}>
                  <input ref={this.myRef} type="text" placeholder="Enter your UID here" />
                </div>
                <div css={loginBtn()}><button type="button" onClick={() => this.login()}>Login</button></div>
              </div>
            </div>
          </React.Fragment>
        );
      }
    else{
      return (
        <React.Fragment>
        <Global styles={loaderStyle} />
      
        <div css={wrapperStyle()}>
            {authRedirect}
            {loader}
            {errorMessage}
            <p css={titleStyle1()}>Loading Your Dashboard.<br></br>Please wait...</p>
          </div>
        </React.Fragment>
      );    
    }

  }
}

const mapStateToProps = state => {
  return {
    loading: state.loading,
    error: state.error,
    isLoggedIn: state.isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogin: ( uid, authKey ) => dispatch( actions.auth( uid, authKey ) )
  };
};

export default connect( mapStateToProps, mapDispatchToProps )( KitchenSinkApp );
